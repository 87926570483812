import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { graphql, StaticQuery } from 'gatsby'
import { Zoom, Fade } from 'react-reveal'

import platformshbg from '../../2019/media/platformsh_bg.png'
import platformsh from '../../2019/media/sponsors/platformsh_white.svg'

import Section from '../Section'

const query = graphql`
  {
    markdownRemark(frontmatter: { slug: { eq: "coding-challenge" } }) {
      html
    }
  }
`

export default ({ action }) => (
  <StaticQuery
    query={query}
    render={data => (
      <Section section="cta cta--darken cta--tertiary" bg={platformshbg}>
        <Fade left distance="20px">
          <Row>
            <Col md={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.markdownRemark.html,
                }}
              />
            </Col>
            <Col className="align-self-center">
              <a href="https://platform.sh" target="_blank" className="">
                <img src={platformsh} />
              </a>
              {action && <p className="text-center">{action}</p>}
            </Col>
          </Row>
        </Fade>
      </Section>
    )}
  />
)
