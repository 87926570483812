import React from 'react'

import techevents from '../media/sponsors/techevents.png'

import { Container, Row, Col } from 'reactstrap'
import Zoom from 'react-reveal/Zoom'

const imgStyle = {
  maxHeight: '120px',
}

export default props => (
  <div>
    <Row className="d-flex align-items-center justify-content-around ">
      <Col xs={12} className="mb-3">
        <h2>Media Partners</h2>
      </Col>
      <Col xs={12}>
        <p>
          Besides our regular partnerships DEV DAY'19 gets some support by media
          partners. We'd like to thank all of them very much to give their best
          to support us and spread the news.
        </p>
      </Col>
    </Row>
    <Row className="mb-3">
      <Col md={4} className="text-center ">
        <a href="https://www.techevents.online/" target="_blank">
          <img src={techevents} style={imgStyle} className="mb-4" />
        </a>
      </Col>
      <Col md={8}>
        Too many Tech Events and too little time to attend? Watch your next Tech
        Event online!{' '}
        <a href="https://www.techevents.online/" target="_blank">
          TechEvents.online
        </a>{' '}
        provides a curated selection of videos from leading Tech Events about
        Saas, Big Data, Lean UX, Virtual Reality and many more subjects. Select
        your favorite Tech Event and watch it online.
      </Col>
    </Row>
  </div>
)
