import React from 'react'
import { graphql, Link } from 'gatsby'
import { Fade } from 'react-reveal'

import { Row, Col, Button } from 'reactstrap'
import Layout from '../components/common/Layout'
import Hero1 from '../components/common/Hero1'
import CodingChallengeIntro from '../components/common/CodingChallenge/Intro'

import Newsletter from '../components/common/Newsletter'

import Sponsors from '../components/2019/components/Sponsors'
import MediaPartners from '../components/2019/components/MediaPartners'
import Impressions from '../components/common/Impressions'

import Section from '../components/common/Section'
import { Element as ScrElement, Link as ScrLink } from 'react-scroll'

import shape06 from '../components/2019/media/figure06.svg'
import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'

import Accordion from '../components/common/Talks/Accordion'

const isServerSide = () => !(typeof window !== 'undefined' && window !== null)

import '../components/2019/scss/main.scss'

export default ({ data }) => (
  <Layout year="2019">
    <Hero1
      section="section section--first"
      xlSize={{ size: 10, offset: 1 }}
      title={<h1 className="display-one">Coding <br />&amp; Beyond</h1>}
      subtitle={
        <p>
          <mark>Recap 2019</mark> Inspiring the Future of Development
          </p>
      }
    >
      <Row>
        <Col lg={6}>
          <Fade left distance="20px">
            <div
              className="has-drop-cap"
              dangerouslySetInnerHTML={{ __html: data.outro1.html }}
            />
          </Fade>
        </Col>
        <Col lg={6}>
          <Fade right distance="20px">
            <div dangerouslySetInnerHTML={{ __html: data.outro2.html }} />
          </Fade>
        </Col>
      </Row>
    </Hero1>

    <Section section="section pt-0">
      <h3>Impressions 2019</h3>
      <Fade left distance="20px">
        <Impressions
          button={Button}
          buttonProps={{
            className: 'btn-block btn--primary mt-3',
          }}
          images={data.images2019.edges.map(({ node }) => node)}
        />
      </Fade>
    </Section>

    <ScrElement name="talks" />
    <Section>
      <h3>Talks 2019</h3>
      <Accordion talks={data.talks.edges.map(({ node }) => node)} />
    </Section>

    <Section>
      <h3>Faro: Dev Day After Party</h3>
      <p className="mt-3 ident">
        The DEV DAY 2019 After Party event consisted of an artistic-cultural
          circuit promoted by <strong>Turbine Kreuzberg PT</strong>, covering
visual and digital arts, design, experimentalism, gastronomy, music
and innovation, aiming to boost the work of local artists, producers
and creatives. Strengthening interconnections between IT &amp;
artistic areas was its motto. Showing that oftenly the best solutions
happen when there are synergies &amp; teamwork.
        </p>
      {!isServerSide() && (
        <Video>
          <source
            src={`https://res.cloudinary.com/turbinekreuzberg/video/upload/v1561984893/devday19/Turbine_kreuzberg_sunset_BY_MediaHive.mp4`}
            type="video/mp4"
          />
        </Video>
      )}

      <p />
    </Section>

    <CodingChallengeIntro
      action={
        <Link className="mt-5 btn" to="/coding-challenge">
          learn more
          </Link>
      }
    />

    <ScrElement name="partners" />
    <Section section="cta section ">
      <Sponsors />
    </Section>

    <ScrElement name="media-partners" />
    <section className="cta cta--darken cta--tertiary">
      <figure
        className="reveal__background  bg-image"
        style={{ backgroundImage: `url(${shape06})` }}
      />
      <Section>
        <MediaPartners />
      </Section>
    </section>

    <Section section="section">
      <Newsletter />
    </Section>
  </Layout>
)

export const query = graphql`
  query Recap2019 {
    outro1: markdownRemark(frontmatter: { path: { eq: "/2019/outro1" } }) {
      html
    }

    outro2: markdownRemark(frontmatter: { path: { eq: "/2019/outro2" } }) {
      html
    }

    talks: allContentfulTalk(
      filter: { year: { eq: 2019 }, node_locale: { eq: "de" } }
      sort: { fields: [slotTime], order: ASC }
    ) {
      edges {
        node {
          id
          node_locale
          title
          slotTime
          location
          youtubeUrl
          slides
          winner
          highlights {
            highlights
          }
          statement {
            statement
          }
          abstract {
            childMarkdownRemark {
              html
            }
          }

          speaker {
            id
            name
            position
            company
            claim
            twitterHandle
            githubHandle
            linkedInProfile
            image {
              title
              fluid(maxWidth: 400, maxHeight: 400) {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
            bio {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    images2019: allCloudinaryImage(
      filter: { context: { custom: { year: { eq: "2019" } } } }
      sort: { fields: context___custom___ordering, order: ASC }
    ) {
      edges {
        node {
          width
          height
          id
          public_id
          secure_url
          scaled_image_url
          eco_image_url
          thumb_dims {
            w
            h
          }
          context {
            custom {
              ordering
              highlight
              alt
              caption
            }
          }
        }
      }
    }
  }
`
