import React from 'react'
import Img from 'gatsby-image'
import SpeakerSocialElements from './SpeakerSocialElements'

export default class Speaker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      speakerModal: false,
    }
  }
  render() {
    const speaker = this.props.speaker
    return (
      <div className="speaker">
        {speaker.image && (
          <Img fluid={speaker.image.fluid} alt={speaker.name} />
        )}

        <h6 className="mb-0 mt-0">{speaker.name}</h6>
        <p className="sub-head mb-0">{speaker.position}</p>
        <p className="sub-head-2 mb-0">{speaker.company}</p>
        <div className="mt-2 d-flex justify-content-around align-items-center">
          <SpeakerSocialElements speaker={speaker} iconSize="2x" />
        </div>
      </div>
    )
  }
}
