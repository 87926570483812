import React from 'react'

import paths from './bg4.json'

const range = 15;

function SvgBg4(props) {

  const activeElement = Math.ceil((props.percentage * paths.length) / 100)

  return (
    <svg viewBox="0 0 3000 2000" {...props}>
      <defs>
        <path id="bg4_svg__a" d="M0 0h3000v2000H0z" />
      </defs>
      <clipPath id="bg4_svg__b">
        <use xlinkHref="#bg4_svg__a" overflow="visible" />
      </clipPath>
      <style type="text/css">
        {`path {
         transition: opacity 250ms ease;
        }`}
      </style>
      <g clipPath="url(#bg4_svg__b)" fill="none" strokeMiterlimit={10}>
        {paths.map((p, idx) => {

          let dist = Math.abs(activeElement - idx)
          dist = (dist > range) ? range : dist

          const ps = {
            opacity: - (1 / range) * dist + 1.15,
            strokeWidth: -(1 / range) * dist + 5
          }

          return <path d={p.d} key={`p-${idx}`} stroke={p.stroke} {...ps} />
        })}
      </g>
    </svg>
  )
}

export default SvgBg4

