import React, { Children } from 'react'

import ResponsiveEmbed from 'react-responsive-embed'
import { animateScroll as scroll, scroller } from 'react-scroll'
import classNames from 'classnames'
import Speaker from './Speaker.js'
import { Row, Col, Button } from 'reactstrap'
import { Fade } from 'react-reveal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import { faSlideshare } from '@fortawesome/free-brands-svg-icons'

const AccordionRow = ({ talk, isActive, onOpen, speaker }) => (
  <div
    id={'talk-' + talk.id}
    className={classNames('accordion__card', {
      'accordion__card--collapse': !isActive,
    })}
  >
    <Fade left distance="20px">
      <div className="accordion__header">
        {talk.winner && (
          <div className="award">
            <FontAwesomeIcon
              icon={faStar}
              size="2x"
              title="Best Speaker Award 2019"
            />
          </div>
        )}

        <label onClick={() => onOpen(talk.id)}>
          <h5 className="margin-bottom-0">
            <div className="trigger">
              <FontAwesomeIcon icon={faCaretUp} size="2x" className="icon" />
              <FontAwesomeIcon icon={faCaretDown} size="2x" className="icon" />
            </div>
            {talk.title}
          </h5>
          <p className="sub-head margin-top-0">{speaker.name}</p>
        </label>
      </div>
    </Fade>
    <div className="accordion__body">
      <div className="accordion__content clearfix">
        {talk.youtubeUrl && isActive && (
          <ResponsiveEmbed src={talk.youtubeUrl} allowFullScreen />
        )}
        <Row className="margin-top">
          <Col md={8}>
            <div
              className="dashed"
              dangerouslySetInnerHTML={{
                __html: talk.abstract.childMarkdownRemark.html,
              }}
            />
            {speaker.claim && (
              <blockquote
                className="dashed"
                dangerouslySetInnerHTML={{
                  __html: speaker.claim,
                }}
              />
            )}
            {talk.slides && (
              <p className="text-center">
                <Button
                  className="btn btn--large btn--secondary btn--circle "
                  href={talk.slides}
                  title="Slides"
                >
                  <FontAwesomeIcon
                    icon={faSlideshare}
                    size="3x"
                    style={{ marginLeft: '-.35em', marginTop: '-.25em' }}
                  />
                </Button>
              </p>
            )}
          </Col>
          <Col>
            <Speaker speaker={speaker} />
          </Col>
        </Row>
      </div>
    </div>
  </div>
)

export default class Accordion extends React.Component {
  constructor(props) {
    super(props)
    const winnerTalks = props.talks.filter(t => t.winner)
    this.state = {
      activeTalkId: winnerTalks[0].id,
    }
    this.handleOpened = this.handleOpened.bind(this)
  }

  handleOpened(talkId) {
    if (this.state.activeTalkId == talkId) {
      this.setState({ activeTalkId: null })
    } else {
      this.setState({ activeTalkId: talkId })
    }

    //yep that's ugly.
    setTimeout(() => {
      scroller.scrollTo(`talk-${talkId}`, {
        offset: -100,
        duration: 400,
        smooth: 'easeInOut',
      })
    }, 100)
  }

  render() {
    return (
      <div className="accordion-block">
        <div className="accordion">
          {this.props.talks.map(talk => (
            <AccordionRow
              key={talk.id}
              talk={talk}
              speaker={talk.speaker[0]}
              isActive={talk.id == this.state.activeTalkId}
              onOpen={this.handleOpened}
            />
          ))}
        </div>
      </div>
    )
  }
}
