import React from 'react'
import { Link } from 'gatsby'
import { Row, Col, Form, Button, FormGroup, Input, Label } from 'reactstrap'
import Validator from 'validator'
import postSubmission from './Forms/postSubmission'

const NETLIFY_FORM_NAME = 'devday-newsletter'

export default class Newsletter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      submission: {
        name: '',
        email: '',
        accept_privacy: false,
      },
      submittable: false,
      submitted: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  submittable(submission) {
    return (
      !Validator.isEmpty(submission.name) &&
      Validator.isEmail(submission.email) &&
      true === submission.accept_privacy
    )
  }

  handleChange(event) {
    const name = event.target.name

    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    const submission = { ...this.state.submission, [name]: value }

    this.setState({
      submission,
      submittable: this.submittable(submission),
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    const submission = this.state.submission
    if (!this.submittable(submission)) {
      alert('not ready yet')
      return false
    }

    postSubmission(NETLIFY_FORM_NAME, submission)
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))

    return false
  }

  render() {
    return (
      <div className="reveal reveal--darken">
        {this.state.submitted ? (
          <div className="text-center">
            <h3>Thank you for signing up, {this.state.submission.name} !</h3>
            <p>We're keeping you posted!</p>
          </div>
        ) : (
            <div>
              <h3 className="text-center">Stay up to date with DEVDAY</h3>
              <div className="reveal__copy padding-top margin-top">
                <Row>
                  <Col lg={{ size: 10, offset: 1 }}>
                    <div id="mc_embed_signup">
                      <Form
                        name={NETLIFY_FORM_NAME}
                        method="post"
                        onSubmit={this.handleSubmit}
                        data-netlify="true"
                        data-netlify-honeypot="firstname"
                      >
                        <input
                          type="hidden"
                          name="form-name"
                          value={NETLIFY_FORM_NAME}
                        />
                        <p hidden>
                          <label>
                            Please provide your first name:{' '}
                            <input name="firstname" />
                          </label>
                        </p>

                        <div id="mc_embed_signup_scroll">
                          <div className="flex-md-wrap">
                            <div className="mc-field-group flex-md-1">
                              <input
                                type="email"
                                name="email"
                                value={this.state.submission.email}
                                className="required"
                                placeholder="Your email address"
                                autoComplete="email"
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="mc-field-group flex-md-1">
                              <input
                                type="text"
                                name="name"
                                value={this.state.submission.name}
                                className="required"
                                placeholder="how should we call you?"
                                autoComplete="given-name"
                                onChange={this.handleChange}
                              />
                            </div>
                            <PrivacyCheckbox
                              submission={this.state.submission}
                              handleChange={this.handleChange}
                              className="d-md-none margin-top margin-bottom"
                            />
                            <div className="mc-field-group">
                              <Button
                                color={
                                  this.state.submittable
                                    ? '-secondary'
                                    : 'tertiary'
                                }
                                disabled={!this.state.submittable}
                                className="btn-block"
                              >
                                Sign up
                            </Button>
                            </div>
                          </div>
                          <PrivacyCheckbox
                            submission={this.state.submission}
                            handleChange={this.handleChange}
                            className="margin-top d-none d-md-block"
                          />
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
      </div>
    )
  }
}

const PrivacyCheckbox = props => (
  <FormGroup check className={props.className}>
    <Label check style={{ color: 'white' }}>
      <Input
        type="checkbox"
        name="accept_privacy"
        className="required"
        onChange={props.handleChange}
        checked={props.submission.accept_privacy}
      />{' '}
      I have read and understood your{' '}
      <Link to="/privacy-policy">privacy policy</Link>
    </Label>
  </FormGroup>
)
