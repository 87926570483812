import React, { useState } from 'react'
import { Fade, Zoom } from 'react-reveal'
import { Container, Row, Col } from 'reactstrap'
import Section from './Section'
import { useTrail } from 'react-spring'

import SvgBg4 from '../2020/media/bg4'

export default props => {
  const [perc, setPerc] = useState(0)

  function onMouseMove(e) {
    setPerc((100 * e.clientX) / window.innerWidth)

  }

  return <section className={`${props.section}`} style={props.style} onMouseMove={onMouseMove}>
    {props.dynamicBackground ? <SvgBg4 style={{ position: 'absolute', width: '100%', height: 'auto', top: 0, left: 0 }} percentage={perc} /> : ''}
    <Container>
      <Row className={props.row}>
        <Col xl={props.xlSize} className={props.col}>
          <header className="page-header">
            {props.subtitle}
            {props.title}
          </header>
          {props.children}
        </Col>
      </Row>
    </Container>

  </section>

}
