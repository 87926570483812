import React from 'react'

import blockstack from '../media/sponsors/blockstack.png'
import jolo from '../media/sponsors/Jolocom_v@3x.png'
import twilio from '../media/sponsors/twilio-logo-red.png'

import { Container, Row, Col } from 'reactstrap'
import Zoom from 'react-reveal/Zoom'

const imgStyle = {
  maxHeight: '120px',
}

export default props => (
  <div>
    <Row className="d-flex align-items-center justify-content-around ">
      <Col xs={12} className="mb-3">
        <h2>Our Partners</h2>
      </Col>
      <Col xs={12}>
        <p>
          This year great partners are supporting DEV DAY'19 and they will also
          dispatch their evangelists to be present at DEV DAY in Berlin! Come
          and meet them in person and ask all the questions you always wanted to
          ask!
        </p>
      </Col>
    </Row>
    <Row className="mb-3">
      <Col md={4} className="text-center ">
        <a href="https://www.twilio.com" target="_blank">
          <img src={twilio} style={imgStyle} className="mb-4" />
        </a>
      </Col>
      <Col md={8}>
        <a href="https://www.twilio.com" target="_blank">
          Twilio
        </a>{' '}
        is a cloud communications platform as a service (CPaaS) company based in
        San Francisco. Twilio has democratized communications channels like
        voice, text, chat, and video by virtualizing the world’s
        telecommunications infrastructure through APIs that are simple enough
        for any developer to use, yet robust enough to power the world’s most
        demanding applications. Their mission is to fuel the future of
        communications.
      </Col>
    </Row>
    <Row className="mb-5">
      <Col md={4} className="text-center">
        <a href="https://jolocom.io" target="_blank">
          <img src={jolo} style={imgStyle} className="mb-4" />
        </a>
      </Col>
      <Col md={8}>
        <a href="https://jolocom.io" target="_blank">
          Jolocom
        </a>{' '}
        is developing a solution which provides users with a decentralised
        infrastructure for self sovereign identity based on hierarchically
        deterministic keys (HD keys) generated, provisioned and controlled by
        the users themselves. They're the creators of the Jolocom Identity
        Protocol, an open source protocol for people and smart agents to
        autonomously create and interact with digital, self-sovereign,
        interoperable identities.
      </Col>
    </Row>
    <Row className="mb-3">
      <Col md={4} className=" text-center">
        <a href="https://blockstack.org" target="_blank">
          <img src={blockstack} style={imgStyle} className="mb-4" />
        </a>
      </Col>
      <Col md={8}>
        <a href="https://blockstack.org" target="_blank">
          Blockstack
        </a>{' '}
        is a new internet for decentralized apps that you access through the
        Blockstack Browser and where users own their data. They provide key
        tools and infrastructure to developers enabling decentralized storage
        and decentralized authentication &amp; identity. Their{' '}
        <a href="https://app.co/mining" target="_blank">
          App Mining
        </a>{' '}
        program pays developers building apps on Blockstack, paying out $100k
        each month across all registered apps. Follow the{' '}
        <a
          href="https://docs.blockstack.org/develop/zero_to_dapp_1.html"
          target="_blank"
        >
          Zero to Dapp{' '}
        </a>{' '}
        tutorial to learn how to get started.
      </Col>
    </Row>
  </div>
)

/*
 <div className="d-flex justify-content-between  align-self-center">
    <Col className="">
      <img src={blockstack} style={imgStyle} />
    </Col>
    <Col className="">
      <img src={twilio} style={imgStyle} />
    </Col>
    <Col className="">
      <img src={jolo} style={imgStyle} />
    </Col>
  </div>
  */
